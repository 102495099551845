<template>
  <div>
    <div v-for="(item,index) in fileList" :key="index" :file="item" class="my-files-view">
      <a :href="item.fileUrl || item.fileUrlMark">
        <img class="fileIcon" :src="changeFileIcon(item)" />
        <span>{{ item.fileName }}</span>
      </a>
    </div>
  </div>
  
</template>

<script>
import wordIcon from '@/assets/wordIcon.png';
import excelIcon from '@/assets/excelIcon.png';
import pptIcon from '@/assets/pptIcon.png';
import pdfIcon from '@/assets/pdfIcon.png';
export default {
  name: 'MyFilesView',
  props:{
    fileList:{
      type: Array,
      require: true,
    },

  },
  data(){
    return{
      fileIcon: '',
    }
  },
  methods: {
    changeFileIcon(file){
      let name = file.fileName;
      let type = name.slice(name.lastIndexOf('.')+1);
      let fileIcon = '';
      if(['doc','docx'].includes(type)){
        fileIcon = wordIcon;
      } else if(['xlsx','xls', 'xlsm'].includes(type)){
        fileIcon = excelIcon;
      } else if(['ppt','pptx'].includes(type)){
        fileIcon = pptIcon;
      } else if(['pdf'].includes(type)){
        fileIcon = pdfIcon;
      } else if(['png','jpg','jpeg', 'ico', 'gif', 'webp'].includes(type)){
        fileIcon = file.fileUrl || file.fileUrlMark;
      }
      return fileIcon
    }
  }
}
</script>
<style lang="scss" scoped>
.my-files-view{
  background-color: #FAFBFD;
  margin: 10px 0;
  padding: 10px;
  a{
    display: flex;
    align-items: center;
    color: $font;
    .fileIcon{
      width: 24px;
      margin-right: 10px;
    }
  }
}
</style>